export default {
  headMenu:{
    about:"关于胜达",
    language:"English",
    core:"核心优势",
    industry:"应用行业",
    product:"产品中心",
    news_center:"新闻中心",
    join_us:"加入我们"
  },
  bottom:{
    tradeWebsite:"胜达-全球贸易通",
    aliLink:"胜达-阿里巴巴",
    domestic:"国内电话",
    division:'国际事业部',
    comAddress:"公司地址",
    address:"新乡市开发区德源西路200号",
    enEmail:"企业邮箱",
    industry:"应用行业",
    classification:"产品分类",
    enterName:"请输入姓名",
    enterMobile:"请输入联系电话",
    enterMsg:"请输入备注留言~",
    submit:"提交"
  },
  video:"观看视频",
  detail:"查看详情",
  learn:"了解更多",
  home:{
    programme:"为您提供行之有效的过滤解决方案",
    icon1:"石油与天然气",
    icon2:"化工与聚合物",
    icon3:"装备制造",
    icon4:"采矿冶金",
    icon5:"电力",
    icon6:"海水淡化与水处理",
    icon7:"新能源",
    icon8:"微电子",
    icon9:"食品饮料",
    icon10:"航空航海",
    about:"关于胜达"
  },
  about:{
    history:"发展历程",
    qualificationHonors:"资质荣誉",
    shengdaQu:"胜达资质",
    shengdaHo:"获得荣誉",
    fileDownload:"资料下载"
  },
  core:{
    researchDevelopment:"研发中心",
    technology:"技术实力",
    service:"销售服务",
    clients:"合作客户",
    styb:"沙特延布",
    stdm:"沙特达曼",
    db:"阿联酋迪拜",
    els:"俄罗斯",
    china:"中国",
    city:"北京、上海、广州、天津等20余个"
  },
  productInfo:{
    display:"产品展示",
    introduction:"设备简介"
  },
  news:{
    corporate:"企业新闻",
    industry:"行业动态",
    tender:"招标公告",
    previous:"上一页",
    next:"下一页",
    location:"您的位置",
    home:"首页",
    dynamics:"企业动态",
    detail:"新闻详情",
    center:"新闻中心"
  },
  join:{
    about:"关于我们",
    home:"首页",
    recruitment:"职位招聘",
    responsibility:"社会责任",
    contacts:"联系人",
    phone:"电话"
  }
};
